<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'academias activas'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: data.counters.players.total,
              icon: 'users',
              change: data.counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entrenadores',
              quantity: data.counters.coaches.total,
              icon: 'chalkboard-teacher',
              change: data.counters.coaches.actives,
              changeVar: 'entrenadores activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: data.counters.categories.total,
              icon: 'chess',
              change: data.counters.categories.actives,
              changeVar: 'categorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>

          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="[{ id: 1, name: 'Activas' },{ id: 0, name: 'Inactivas' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>

          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.verified"
              placeholder="Verificación"
              :options="[{ id: 1, name: 'Verificadas' },{ id: 0, name: 'Pendientes' },{ id: 2, name: 'Rechazadas' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          variant="warning"
          class="my-2 md:my-auto mr-2 h-fit py-2"
          @click="$router.push('/academias/crear')"
        >
          Crear academia
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="academy"
          :headers="headers"
          :url="'academias'"
          :data="academies"
          :route="'/academy'"
          :tableName="'ACADEMIAS'"
          :hasDetail="false"
          :hasDetail2="true"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Nombre",
        "Coordinador",
        "Correo",
        "Categorías",
        //"Código",
        //"Instagram",
        "Jugadores",
        "Acciones"
      ],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        active: 1,
        verified: 1,
        order_key: 'name',
        order_value: 'asc'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    academies() {
      return this.$store.getters["global/getItems"]("academy", "elements").map(
        (el) => ({
          id: el.id,
          img_logo: el.url_logo,
          name: el.name,
          coordinator: el.coordinator,
          email: el.email,
          categories: el.categories,
          //token: el.token,
          //instagram: el.instagram,
          players: el.players,
          active: el.active,
          verified: el.verified
        })
      )
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.academies.actives--
    },
    activate() {
      this.data.counters.academies.actives++
    },
    erase() {
      this.data.counters.academies.total--
      this.data.counters.academies.actives--
    }
  },
  created() {
    this.getCounters()
  }
}
</script>
