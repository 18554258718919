var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"},[_c('t-loader',{attrs:{"extraClass":"border-primary-900 w-12 h-12"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"},[_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Academias',
            quantity: _vm.data.counters.academies.total,
            icon: 'shield-alt',
            change: _vm.data.counters.academies.actives,
            changeVar: 'academias activas'
          },"bgColor":"bg-red-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Jugadores',
            quantity: _vm.data.counters.players.total,
            icon: 'users',
            change: _vm.data.counters.players.actives,
            changeVar: 'jugadores activos'
          },"bgColor":"bg-green-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Entrenadores',
            quantity: _vm.data.counters.coaches.total,
            icon: 'chalkboard-teacher',
            change: _vm.data.counters.coaches.actives,
            changeVar: 'entrenadores activos'
          },"bgColor":"bg-orange-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Categorías',
            quantity: _vm.data.counters.categories.total,
            icon: 'chess',
            change: _vm.data.counters.categories.actives,
            changeVar: 'categorías activas'
          },"bgColor":"bg-yellow-1100"}})],1)]),_c('div',{staticClass:"flex flex-col md:flex-row align-center justify-between my-4"},[_c('div',{staticClass:"flex flex-col md:flex-row align-center"},[_c('div',{staticClass:"my-auto mx-2"},[_c('searchbar',{attrs:{"placeholder":"Buscar"},on:{"changeQuery":_vm.changeQuery},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('t-input-group',{staticClass:"mx-2"},[_c('t-select',{attrs:{"placeholder":"Estatus","options":[{ id: 1, name: 'Activas' },{ id: 0, name: 'Inactivas' }],"textAttribute":"name","valueAttribute":"id"},model:{value:(_vm.parameters.active),callback:function ($$v) {_vm.$set(_vm.parameters, "active", $$v)},expression:"parameters.active"}})],1),_c('t-input-group',{staticClass:"mx-2"},[_c('t-select',{attrs:{"placeholder":"Verificación","options":[{ id: 1, name: 'Verificadas' },{ id: 0, name: 'Pendientes' },{ id: 2, name: 'Rechazadas' }],"textAttribute":"name","valueAttribute":"id"},model:{value:(_vm.parameters.verified),callback:function ($$v) {_vm.$set(_vm.parameters, "verified", $$v)},expression:"parameters.verified"}})],1)],1),_c('t-button',{staticClass:"my-2 md:my-auto mr-2 h-fit py-2",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$router.push('/academias/crear')}}},[_vm._v(" Crear academia ")])],1),_c('div',{staticClass:"w-full"},[_c('basic-table',{ref:"list-table",attrs:{"module":"academy","headers":_vm.headers,"url":'academias',"data":_vm.academies,"route":'/academy',"tableName":'ACADEMIAS',"hasDetail":false,"hasDetail2":true,"parameters":_vm.parameters},on:{"deactivate":_vm.deactivate,"activate":_vm.activate,"erase":_vm.erase}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }